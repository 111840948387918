/* 
#chat1 .form-outline .form-control:focus~.form-label {
    color: #ff0000;
}

#chat1 .form-outline .form-control~.form-label {
    color: #bfbfbf;
} */



#chat2 .form-control {
border-color: transparent;
}

#chat2 .form-control:focus {
border-color: transparent;
box-shadow: inset 0px 0px 0px 1px transparent;
}

.divider:after,
.divider:before {
content: "";
flex: 1;
height: 1px;
background: #eee;
}

